
.light-mode {
  --primary-heading: #50aed9;
  --secondary-heading: #2b92c2;
  --matching-heading : rgb(226, 139, 241);
  --primary-heading-translucent : #50aed941;
  

  --primary-text: #1d1a1a;
  --primary-bg-color: rgb(231, 231, 231);
  --matching-bg-color: rgb(195, 195, 195);

  --secondary-text: #252425;
  --secondary-bg-color: #f1f1f1;

  --border-color: #100f0f35;
  --link-color: #233971;

  --matching-bg-color-1: rgb(241, 241, 241);
  --matching-bg-color-2: rgb(211, 211, 211);
}

.dark-mode {
  --primary-heading: #50aed9;
  --secondary-heading: #3299c9;
  --matching-heading : rgb(219, 110, 239);
  --primary-heading-translucent : #50aed941;

  --primary-text: #ffffff;
  --primary-bg-color: #151515;  
  --matching-bg-color: rgb(98, 118, 127);
  --matching-bg-color-1: rgb(51, 51, 51);
  --matching-bg-color-2: rgb(32, 32, 32);


  --secondary-text: #e9e9e9;
  --secondary-bg-color: #222222;
  --border-color: #a5a2a22e;
  --link-color : #4088cc;
  --low-white : #cecece;
}

.color-red {
  --primary-heading: #ec1839;
  /* --primary-heading: #de2626; */
  --secondary-heading: #e31111;
  --matching-heading : #ef7432a7;
    --primary-heading-translucent : #ec18392a;
}

.color-blue {
  --primary-heading: #875cee;
  --secondary-heading: #4e24b0;
  --matching-heading : #4693eab2;
    --primary-heading-translucent : #885cee2a;
  
  
}
.color-green {
  --primary-heading: #25ca85;
  --secondary-heading: #18b172;
  --matching-heading : #068590b7;
    --primary-heading-translucent : #25ca852a;
}
.color-yellow {
  --primary-heading: #d1b825;
  --secondary-heading: #acae1c;
  --matching-heading : #d07834;
    --primary-heading-translucent : #d1b7252b;
}

.background-color-red {
  background-color: #de2626;
}
.background-color-blue {
  background-color: #5432a4;
}
.background-color-green {
  background-color: #25ca85;
}
.background-color-yellow {
  background-color: #cbb84d;
}
.background-color-default {
  background-color: #50aed9;
}





/* --exception color--- */
/* .low-white {
  color : #cecece;
} */
