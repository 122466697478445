.skill__container {

  box-sizing: border-box;
  padding: 0px 50px;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  scrollbar-width: thin;
  height: 100%;
}