


.app__container{
  background-color: var(--primary-bg-color);
  height: 100%;
  display: flex;
  color: var(--primary-text);
  box-sizing: border-box;
}

.app__container_sidebar{
  width: 20%;
  min-width: 200px;
  height: 100%;
  background-color: var(--secondary-bg-color)

}

.app__container_main-page {
  background-color: var(--primary-bg-color);
  width: 100%;
  height: 100%;
}



.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease-in-out;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

