.experience__main{
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  height: 100%;
  display: block;
  overflow: auto;
  overflow-x: hidden;
  
}

.experience__container{
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-top: 20px;
}