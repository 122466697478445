.contact__container {
  padding : 0px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.contact__container_connection_message{
    text-align: center;
    margin-bottom: 20px;
}

.contact_logo_container{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;

}

.contact__detail {
  display: flex;
  width: 35%;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  background-color: var(--secondary-heading);
  border-radius: 20px;
  border-top-right-radius: 70px;
  box-shadow: 50px -20px var(--matching-heading);
  margin-bottom: 20px;
}
.contact__detail_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

}
.contact__detail_title {
  font-weight: bold;
  min-width: 100px;

}
.contact__detail_title {
  font-weight: bold;
  min-width: 100px;

}


.contact__open-mail {
  cursor: pointer;
}