.popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 80%;
  max-width: 600px;
  background-color: var(--matching-bg-color);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.popup-modal h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px;
  margin-bottom: 30px;
}

.popup-modal p {
  font-size: 18px;
}



.popup-modal button:focus {
  outline: none;
}

.popup-modal button:hover {
  background-color: var(--primary-bg-color);
}
