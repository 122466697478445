
.about__container{
  background-color: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  display: flex;

}
.about__container_text{
  width: 60%;
  height: 100%;
  padding-right: 30px;
  padding-left: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.about__text_container_heading {
  width: 100%;;
  text-align: left;
  font-size: 25px;
  font-weight: 500;

}
.about__text_container_typer {
  width: 100%;;
  text-align: left;
  min-height: 40px;
  font-size: 28px;
  margin-top: 20px;
}
.about__text_container_bio {
  width: 100%;
}
.about__text_container_bio {
  font-weight: 500;
  line-height: 1.4;
  color: var(--low-white);
  font-size: 18px;;
}

.about__text_container_bio a {
  text-decoration: none;
}




/* ---------   Image code  ------------ */

.about__container_image{
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

}

.about__image_container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 90%;
}

.about__image_container_top-left {
  width: 80px;
  height: 80px;
  border-left: 8px solid var(--primary-heading);
  border-top: 8px solid var(--primary-heading);
}

.about__image_container_img {
  flex: 1;
  width: 90%;
  object-fit: cover;
  position: relative;
  /* clip-path: polygon(0 0, 0 40px, 40px 0, 80px 0, 80px 40px, 40px 80px, 0 80px); */
    margin-top: -50px;
}

.about__image_container_bottom-right {
  width: 80px;
  height: 80px;
  margin-left: auto;
  border-right: 8px solid var(--primary-heading);
  border-bottom: 8px solid var(--primary-heading);
  margin-top: -50px;
}
