.heading__container {
  padding-left: 10px;
  padding-top: 50px;
  padding-bottom: 30px;
}

.heading__container_h1 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 7px;
}

.heading__container_first-line {
  width: 70px;
  height: 5px;
  background-color: var(--primary-heading);

}
.heading__container_second-line {
  width: 35px;
  height: 5px;
  background-color: var(--primary-heading);
  margin-top: 7px;
}