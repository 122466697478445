.project__main {
  height: 100%;
  overflow: auto;
  
}


.project__container{
  padding: 40px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
