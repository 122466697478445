.item__container_main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0px;
}

.item__container_item{
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.item__container_name {
  font-size: 29px;
  color: var(--primary-text);
  margin: 10px;
  padding-left: 10px;
  border-left:  5px solid var(--secondary-heading);


}

