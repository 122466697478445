
.listitem__container {
  width: 100%;
  padding-left: 80px;
  padding-top: 40px;
  text-align: left;

}

.listitem__container_a {
  font-size: 18px;
  max-width: 140px;
  border-bottom: .5px solid var(--border-color);
  cursor: pointer;
  display: block; 
  font-weight: 600;
  color: var(--primary-text);
  transition: color 0.3s ease-in-out;
  transform: 0.2s ease-in-out;
  padding-bottom: 5px;


}
.listitem__container_a_active {
  color: var(--primary-heading);
  transform: scale(1.15);
}

.listitem_i {
  padding-right: 10px;
}



/* .list-item:link {
  color: red;
}

.list-item:visited {
  color: green;
}

.list-item:hover {
  color: hotpink;
}

.list-item:active {
  color: blue;
} */