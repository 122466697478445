
.sidebar__container{
  border-right: 1px solid var(--border-color);
  height: 100%;


}

.slidebar__ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;

}

.sidebar__logo_container {
  display: flex;
  justify-content: center;
}
.sidebar__logo {
  width: 100px;
  height: 100px;
  position: absolute;
  margin-top: 30px;;

}
