.project-item__main {
  width: 30%;
}
.project-item__container {
    max-width: 270px;
    height: fit-content;
    min-height: 100px;
    background-color: var(--matching-bg-color-1);
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    border: 1px solid #443b0f47;
      margin-bottom: 70px;
      overflow-x: hidden;
}

.project-item__image {
  width: 250px;
  height: fit-content;
  margin: 5px;
  border: 5px;
  margin-top: 10px;
  border-radius: 10px;
  height: 140px;

  display: block;
}

.project-item__description{
  font-size: 14px;
  text-align: center;
  color: var(--secondary-text);
  margin-top: 5px;
  max-lines: 3;
}


.project-item__access {
  margin-bottom: 15px;
  margin-top: -20px;
  display: flex;
  justify-content: space-evenly;

}

.project-item__head_name {
  font-size: 22px;
  margin-top: 7px;
  text-align: center;
  font-family: 'Lato';
  font-weight: 600;
}

/* ----------------------- */
.project-item__button_conf {
  padding: 7px 20px;
  margin-right: 7px

}

.fa-globe {
  padding-right: 5px;
}
